  * {
    margin: 0;
    padding: 0;
  }

  
  /*html {
    scroll-behavior: smooth;
  }*/

  /*  COLOR BARRA PUBLICA */

  .fondo-barra {
    background-color: gray;
    border-radius: 0;
    

  }


  /* BARRA NEGRA TRANSPARENTE CARRUSEL                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             */
  .carrusel {
    background-color: rgba(0, 0, 0, 0.7);
    /* Color de fondo del caption */
    padding: 0px;
    /* Espaciado interno */
    height: 30%;
    /* Ajusta la altura según tus necesidades */
    width: 70%;
    /* Asegura que ocupe el ancho completo del carrusel */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* Posicionamos el caption de forma absoluta */
    bottom: 100px;
    /* Colocamos el caption en la parte inferior */
    left: 0;
    /* Lo alineamos a la izquierda */
    right: 0;
    /* Lo alineamos a la derecha */
  }


  .btnFiltros {

    background-color: rgb(92, 91, 91);
    border-color: rgb(92, 91, 91);
  }

  .btn:hover {
    background-color: rgb(50, 49, 49);
    border-color: rgb(92, 91, 91);
  }

  /* Estilos para pantallas grandes */
  .carrusel h1 {
    font-size: 48px;
  }

  .carrusel p {
    font-size: 20px;
  }



  .carousel-item img {
    height: 700px;
    /* Altura inicial de la imagen */
    width: 100%;
    /* Ancho de la imagen al 100% del contenedor */
    object-fit: cover;
    /* Ajustar la imagen para que cubra completamente el contenedor */
  }

  .nosotros {
    background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../img/justicia.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 500px;
    /* Establece una altura mínima */
    height: auto;
    /* Permitirá que la sección se ajuste automáticamente según el contenido */
  }



  .titulo {

    padding-top: 80px;
    margin-bottom: 80px;
    font-weight: 700;
    border-bottom: 2px solid #000;
    /* Ajusta el color y grosor de la línea según tus preferencias */
    display: inline-block;
    /* Para que la línea se ajuste al ancho del contenido */
    padding-bottom: 5px;

  }


  .subtituloNosotros {
    text-align: center;
    margin-bottom: 60px;
  }

  .titulo-receptores {

    font-weight: 700;
    border-bottom: 2px solid #000;
    display: inline-block;
    margin-bottom: 80px;
  }



  .contenido-completo-receptores {

    margin-top: 1000px;
  }



  .textoDetalle-nosotros {

    text-align: justify;
  }



  .noticias {

    background-color: rgb(239, 239, 239);
    height: 800px;

  }

 _

  .tipoSistema {
    width: 225px;
    text-align: center;
    justify-content: center;

  }


  
  



  /* 

NO SE OCUPA
.menu {

  margin-left: 20px;
  text-decoration: none;
  color: white;

} */


  /***  SECCION CONTACTO -------------------------------------------------------------------------------- ***/

  .contacto {

    margin-top: 100px;
    height: 900px;

  }

  /* SOMBRA A LA SECCION DE CONTACTO */
  .contactoSec {

    box-shadow: 10px 10px 12px rgba(0, 0, 0, 0.3);
    background: linear-gradient(to right, white 70%, gray 30%);
    height: 800px;
    margin-top: 50px;

  }

  .form-contacto {

    padding-left: 0;
    /* O un valor más pequeño según tus necesidades */
    padding-right: 100px;
  }


  .formulario {

    width: auto;
    height: 600px;
    margin-top: 90px;
    /* Esto centrará el formulario horizontalmente */
    text-align: left;
    /* Esto alineará el texto a la izquierda dentro del formulario */


  }

  .titulo-contacto {

    margin-top: 80px;
    margin-bottom: 20px;
    font-weight: 700;
    border-bottom: 2px solid #000;
    /* Ajusta el color y grosor de la línea según tus preferencias */
    display: inline-block;
    /* Para que la línea se ajuste al ancho del contenido */
    padding-bottom: 5px;
  }

  .listado-datos {

    list-style: none;

  }

  .contenedor-imagen {
    text-align: center;
    /* Centra horizontalmente el contenido */
  }

  .imagenContacto {

    /* Puedes establecer un ancho máximo si deseas limitar el tamaño de la imagen */
    max-width: 100%;
    height: auto;
    border-radius: 50px;

  }

  /***  FIN SECCION CONTACTO ----------------------------------------------------------------------------------------------- ***/

  .cardReceptores {

    max-width: 100%;
    margin: auto;


  }


  .tituloJurisdiccion {

    white-space: nowrap;
    /* Evita que el texto se divida en múltiples líneas */
    overflow: hidden;
    /* Oculta el texto que desborda el contenedor */
    text-overflow: ellipsis;
    /* Agrega puntos suspensivos al final del texto */
    max-width: 100%;
    /* Establece un ancho máximo para el título */
  }




  .modal-receptores {


    padding: 10px;
    border: 1px solid black;


  }

  .modalimg {
    background-color: #969696;
  }

  .mapa {
    height: 200px;
    width: 380px;
    margin-bottom: 20px;

  }

  .checkbox-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Divide en tres columnas */
    grid-column-gap: 5px;
    /* Espacio horizontal entre columnas */
    grid-row-gap: 5px;
    /* Espacio vertical entre elementos */

  }

  /* Estilos adicionales según tus preferencias */
  .checkbox-label {
    display: flex;
    align-items: center;

  }

  /* Estilos adicionales según tus preferencias */


  .datos-receptor {
    border: 1px solid black;
  }

  .contenido {


    padding: 50px;
    margin-bottom: 80px;
    text-align: center;
    overflow-x: hidden;
  }


  .checkbox-container {

    flex-wrap: wrap;
  }

  .checkbox-label {
    align-items: center;
    margin-right: 10px;
    /* Agrega margen derecho para separar los elementos */
  }


  .fa-spin-container {
    animation: fa-spin 2s infinite linear;
  }


  .imgModal {

    width: 250px;
    height: 250px;


  }

  .imgSistema {

    width: 60%;


  }

  .cargadorModal {
    margin-right: 500px;
    padding: 100px;

  }

  


  .linea-horizontal {
    width: 400px;
    border: 1px solid #686868;
    margin: 10px 0;
  }

 

  .seccionGestionarReceptores,
  .seccionGestionarNoticias,
  .seccionGestionarCarrusel,
  .seccionGestionarNosotros {

    padding: 80px;
  }

  .btnCrear {

    margin-bottom: 20px;
    margin-top: 20px;
  }

  .btnGuardar {

    margin-top: 20px;

  }

  .tituloTablas {
    margin-bottom: 40px;
  }





  .footer {
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    background-color: gray;
    /* Cambia el color de fondo según tus preferencias */
    padding: 10px 20px;
    /* Agrega espaciado según tus preferencias */

  }

  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .content {
    flex-grow: 1;
  }

  .footer-text,
  .footer-logo,
  .footer-social-icons {
    flex: 1;
    text-align: center;
    /* Centra el contenido horizontalmente en cada sección */
    color: white;
  }

  .footer-logo {
    flex: 1;
    /* Hace que el logo sea el doble de ancho que los otros elementos */
  }


  .square-image-container {
    width: 100%;
    padding-top: 100%;
    /* 1:1 Aspect Ratio (cuadrado) */
    position: relative;
    overflow: hidden;
  }

  /* Establece la imagen como fondo del contenedor */
  .square-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }


  .imgModalNoticias{

    height: 100px;


  }

  .Msjvalidaciones{

    font-weight: 500;


  }





  /* MEDIA QUERY   */
  

  /* Estilos para pantallas medianas */
  @media (max-width: 991px) {
    .carousel-item img {
      height: 500px;
      /* Ajustar la altura de la imagen para pantallas medianas */
    }

    .carousel-caption h1 {
      font-size: 36px;
    }

    .carousel-caption p {
      font-size: 18px;
    }

    .form-contacto {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 80px;


    }

    .contenedor-imagen {
      display: none;
    }
    
    .modal-receptores .container .row .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .formulario{

      margin-right: 80px;
    }



  }

  /* Estilos para pantallas pequeñas */
  @media (max-width: 767px) {
    .carousel-item img {
      height: 300px;
      /* Ajustar la altura de la imagen para pantallas pequeñas */
    }

    .carousel-caption h1 {
      font-size: 24px;
    }

    .carousel-caption p {
      font-size: 16px;
    }


    .subtituloNosotros {
      margin-bottom: 30px;
      margin-top: 30px;
     
    }


    .formulario {

      width: auto;
      height: 300px;
      margin-top: 90px;
      margin: 50px;
    }

    .form-contacto {
      margin: 0px;
      margin-left: 10px;
    }

    .subtituloNoticias {

      margin-bottom: 30px;
    }
    
    


    .contactoSec {

      box-shadow: 10px 10px 12px rgba(0, 0, 0, 0.3);
      background: linear-gradient(to right, white 70%, gray 30%);
      max-width: 350px;
      height: 550px;


    }

    .contenedor-imagen {
      display: none;
    }


    .tarjeta {

      justify-content: center;

    }

    .cardNoticias {
      width: 100%;
      /* Ancho al 100% en pantallas pequeñas */


    }

    .footer {
      height: 150px;

    }

    .footer-text,
    .footer-social-icons {
      font-size: 12px;
    }

    .contacto {

      margin-top: 100px;
      height: 700px;

    }

    .titulo-receptores {

      margin: 20px;

    }


    .mapa {
      height: 150px;
      width: 250px;
      margin-bottom: 20px;
  

    }

    .linea-horizontal {

      max-width: 100%;
    }


    .contenidoTiposistema {
      display: flex;
      justify-content: center;
    }

    .cargadorModal {
      margin-right: 10px;
      padding: 100px;

    }

    .iconoBuscando {

      size: 50px;
    }

    .modal-receptores {

      padding: 20px;


    }

    .tituloDatos {

      display: none;
    }

    .imgModal {

      width: 200px;
      height: 200px;
  
    }

    .contenido{
      margin: 7px;
      margin-top: 50px;
      margin-bottom: 80px;
      padding: 0px
    }


  }

  


